import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Section from '../../components/Section/Section';
import { BREAKPOINT, COLOR } from '../../utils/constants';
import Lframe from '../../components/Lframe/Lframe';
import VisaOffers from '../../components/VisaOffers/VisaOffers';

const VisaOffersPage = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer" css="background-color: #f8f8f8;">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/credit-cards/visa-offers/"
        />
        <title>Visa Offers | Latitude Financial</title>
        <meta
          name="description"
          content="Turn everyday purchases into something special with our Latitude Infinity Rewards card offering up to 55 days interest free on purchases."
        />
      </Helmet>

      <Lframe infinity simple />

      <HeroBranded
        simple
        imageContent={
          <div
            css={`
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (min-width: ${BREAKPOINT.LG}) {
                justify-content: flex-end;
              }
            `}
          />
        }
        title={<>Visa Offers</>}
      />
      <div style={{ background: COLOR.GREY5 }}>
        <Section>
          <VisaOffers />
        </Section>
      </div>
    </main>
  </Layout>
);

export default VisaOffersPage;
